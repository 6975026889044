import { useState } from 'react';

import type { ChangeEventHandler } from 'react';

import {
	ActionIcon,
	Box,
	Button,
	CopyButton,
	createModal,
	createStyles,
	Icon,
	rem,
	Textarea,
	TextInput,
	TFText,
	Tooltip,
	useModalControl,
} from '@tf/ui';

import { useCreateTokenMutation } from '@/core/api/tokens';

const useStyles = createStyles(() => {
	return {
		token: {
			fontFamily: 'monospace',
			paddingRight: '2.5rem',
			border: 'none',
		},
	};
});

export const AddTokenModal = createModal(() => {
	const { classes } = useStyles();
	const { onClose } = useModalControl();

	const createTokenMutation = useCreateTokenMutation();

	const [selectedName, setSelectedName] = useState('');
	const [displayedToken, setDisplayedToken] = useState('');

	const handleNameChange: ChangeEventHandler<HTMLInputElement> = (e) => setSelectedName(e.target.value);

	const handleSave = async () => {
		await createTokenMutation.mutateAsync(
			{
				name: selectedName,
				grants: ['data_import'], // hardcoded value!
			},
			{
				onSuccess: (data) => {
					setDisplayedToken(data.token);
				},
			}
		);
	};

	if (!displayedToken) {
		return (
			<>
				<TextInput
					m="0.75rem"
					mt="1rem"
					label="Token name"
					name="firstName"
					placeholder=""
					value={selectedName}
					onChange={handleNameChange}
				/>

				<Box
					sx={({ colors }) => ({
						display: 'flex',
						gap: '0.75rem',
						padding: '0.75rem',
						marginTop: '1rem',
						borderTopStyle: 'solid',
						borderTopWidth: '1px',
						borderTopColor: colors.gray[2],
					})}
				>
					<Button variant="light" size="md" w="100%" onClick={onClose}>
						Cancel
					</Button>
					<Button
						size="md"
						w={'100%'}
						loading={createTokenMutation.isPending}
						disabled={!selectedName}
						onClick={handleSave}
					>
						Create
					</Button>
				</Box>
			</>
		);
	} else {
		return (
			<>
				<TFText
					m="0.75rem"
					mt="1rem"
					sx={{
						display: 'flex',
						alignItems: 'center',
						gap: '0.5rem',
					}}
				>
					<Icon.IconCircleCheck />
					Your new token has been successfully created
				</TFText>
				<Textarea
					m="0.75rem"
					mt="1rem"
					autosize
					readOnly={true}
					classNames={{
						input: classes.token,
					}}
					value={displayedToken}
					rightSection={
						<CopyButton value={displayedToken} timeout={2000}>
							{({ copied, copy }) => (
								<Tooltip label={copied ? 'Copied' : 'Copy'} withArrow position="right">
									<ActionIcon h="90%" color={copied ? 'teal' : 'gray'} variant="subtle" onClick={copy}>
										{copied ? (
											<Icon.IconCheck style={{ width: rem(20) }} />
										) : (
											<Icon.IconCopy style={{ width: rem(20) }} />
										)}
									</ActionIcon>
								</Tooltip>
							)}
						</CopyButton>
					}
				/>

				<TFText m="0.75rem" mt="1rem">
					Make sure you save it — you won&apos;t be able to access it again
				</TFText>
				<Box m="0.75rem" mt="1rem">
					<Button variant="light" size="md" w="100%" onClick={onClose}>
						Ok
					</Button>
				</Box>
			</>
		);
	}
});
