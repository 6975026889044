import { useState } from 'react';

import { SignOutButton, UserProfile } from '@clerk/clerk-react';
import { useSelector } from '@datagrid/state';
import * as Sentry from '@sentry/react';
import { Link, useNavigate } from 'react-router-dom';

import { AppShell, Box, Button, createStyles, Icon, Menu, Modal } from '@tf/ui';

import { useCurrentUser } from '@/core/hooks';
import { appStore } from '@/core/stores';
import { UserCapabilities } from '@/core/types';
import { getDisplayName, useCurrentUserCapabilityCheck } from '@/core/utils';
import { HaveIssuesPopover } from '@/components/shared';
import { AppMenu } from '@/components/shared/AppLayout/AppMenu';
import { UserAvatar } from '@/components/shared/UserAvatar';

import { NotificationCenter } from '../NotificationCenter';

const useStyles = createStyles(({ breakpoints, colors }) => ({
	root: {
		height: 44,
		padding: '0 1rem',
		border: '1px solid #EAECF0',
	},
	inner: {
		display: 'flex',
		alignItems: 'center',
		maxWidth: breakpoints.xl,
		margin: '0 auto',
	},
	userToolbar: {
		height: 44,
		fontWeight: 500,
		padding: '0 1rem',
		marginLeft: '1.2rem',
		color: colors.gray[6],
		'&:hover': {
			backgroundColor: 'transparent !important',
			color: colors.dark[7],
		},
	},
	userMenuItem: {
		fontSize: '.8rem',
		height: 36,
	},
}));

export const AppHeader = () => {
	const { classes } = useStyles();
	const navigate = useNavigate();
	const canManageUsers = useCurrentUserCapabilityCheck(UserCapabilities.CAN_MANAGE_USERS);
	const canReviewAccount = useCurrentUserCapabilityCheck(UserCapabilities.CAN_REVIEW_ACCOUNTS_DATA);
	const canExportData = useCurrentUserCapabilityCheck(UserCapabilities.CAN_EXPORT_ACCOUNT_DATA);
	const canManageApiTokens = useCurrentUserCapabilityCheck(UserCapabilities.CAN_MANAGE_API_TOKENS);
	const user = useCurrentUser();

	// TODO: Remove ' ?? []' when CRA goes to prod; added as a safety measure if new frontend version will be deployed with old defs
	const paletteItems = useSelector(() => appStore.defs.paletteItems.get()) ?? [];

	const [isProfileOpen, setIsProfileOpen] = useState(false);

	const logout = () => {
		Sentry.setUser(null);
		navigate('/');
	};

	return (
		<AppShell.Header className={classes.root}>
			<Box className={classes.inner}>
				<AppMenu />

				<Box mr=".5rem">
					<NotificationCenter />
				</Box>

				<HaveIssuesPopover />

				<Menu
					withinPortal
					width={200}
					offset={0}
					withArrow={false}
					position="bottom-end"
					classNames={{ item: classes.userMenuItem }}
				>
					<Menu.Target>
						<Button
							className={classes.userToolbar}
							variant="subtle"
							px={0}
							rightSection={<UserAvatar userInfo={user} size={28} />}
						>
							{getDisplayName(user)}
						</Button>
					</Menu.Target>

					<Menu.Dropdown>
						{canManageUsers && (
							<Menu.Item
								component={Link}
								leftSection={<Icon.IconSettings2 size={14} />}
								to="/settings/users"
							>
								Workspace settings
							</Menu.Item>
						)}

						{canReviewAccount && paletteItems.includes('cra_calculation') ? (
							<Menu.Item
								component={Link}
								leftSection={<Icon.IconFileTypeXls size={14} />}
								to="/settings/cra"
							>
								CRA settings
							</Menu.Item>
						) : null}

						{canManageApiTokens && (
							<Menu.Item component={Link} leftSection={<Icon.IconKey size={14} />} to="/settings/tokens">
								Manage API tokens
							</Menu.Item>
						)}

						{canExportData && (
							<Menu.Item
								component={Link}
								leftSection={<Icon.IconTableExport size={14} />}
								to="data-export"
							>
								Data export
							</Menu.Item>
						)}

						<Menu.Item
							leftSection={<Icon.IconUser size={14} />}
							onClick={() => setIsProfileOpen(!isProfileOpen)}
						>
							Profile settings
						</Menu.Item>

						<SignOutButton>
							<Menu.Item
								color="red"
								leftSection={<Icon.IconLogout size={14} />}
								onClick={() => logout()}
							>
								Sign out
							</Menu.Item>
						</SignOutButton>
					</Menu.Dropdown>
				</Menu>
				<Modal
					title="Profile settings"
					size={1000}
					opened={isProfileOpen}
					onClose={() => setIsProfileOpen(!isProfileOpen)}
				>
					<UserProfile
						appearance={{
							elements: {
								cardBox: { borderRadius: '0px' },
								scrollBox: { borderRadius: '0px' },
							},
						}}
					/>
				</Modal>
			</Box>
		</AppShell.Header>
	);
};
