import { useEffect } from 'react';

import { useAuth } from '@clerk/clerk-react';
import { useSelector } from '@datagrid/state';
import type React from 'react';

import { PublicApiClient } from '@tf/api';

import { configStore } from '@/core/stores';

export const PublicApiClientInitialization: React.FC = () => {
	const config = useSelector(() => configStore.get());
	const { getToken } = useAuth();

	useEffect(() => {
		const fetchWithStatusCheck = async (input: RequestInfo | URL, init?: RequestInit) => {
			const token = await getToken({ template: 'trustform' });
			return await fetch(input, {
				...init,
				headers: {
					...init?.headers,
					Authorization: `Bearer ${token}`,
				},
			});
		};

		PublicApiClient.init(config.urls.api, fetchWithStatusCheck);
	}, [config.urls.api, getToken]);

	return null;
};
